export default function Mail({ mailAddress, classes, fontSize }) {
  return (
    <a
      href={`mailto:${mailAddress}`}
      className={`flex ${classes}`}
      style={{ fontSize: `${fontSize}` }}
    >
      <svg
        style={{ width: `${fontSize}` }}
        className="mr-1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          d="M64 96c-17.7 0-32 14.3-32 32v39.9L227.6 311.3c16.9 12.4 39.9 12.4 56.8 0L480 167.9V128c0-17.7-14.3-32-32-32H64zM32 207.6V384c0 17.7 14.3 32 32 32H448c17.7 0 32-14.3 32-32V207.6L303.3 337.1c-28.2 20.6-66.5 20.6-94.6 0L32 207.6zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"
          fill="currentColor"
        />
      </svg>
      <span>{mailAddress}</span>
    </a>
  );
}
