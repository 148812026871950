import Divider from "./Divider";
import Price from "./Price";


function Prices() {
    return (
        <div id="prices" className="mb-20 py-6">
            <div className="container mx-auto">
                <h2 className="mb-8 text-center">Preise</h2>
                <Divider />
                <div className="women mb-6">
                    <h3 className="text-center text-2xl mb-6 text-stone-600">Damen</h3>
                    <Price service="Waschen & Föhnen" variant="kurzes Haar" amount="ab" value="18,50" />
                    <Price service="Waschen & Föhnen" variant="langes Haar" amount="ab" value="22,50" />
                    <Price service="Waschen & Legen" variant="kurzes Haar" amount="ab" value="19,50" />
                    <Price service="Waschen & Legen" variant="langes Haar" amount="ab" value="23,50" />
                    <Price service="Waschen & Schneiden" variant="" amount="ab" value="23,00" />
                    <Price service="Schneiden" variant="" amount="ab" value="20,50" />
                    <Price service="Pony Schneiden" variant="" amount="" value="6,00" />
                </div>
                <div className="men mb-6">
                    <h3 className="text-center text-2xl mb-6 text-stone-600">Herren</h3>
                    <Price service="Waschen, Schneiden & Föhnen" variant="" amount="ab" value="25,50" />
                    <Price service="Waschen & Schneiden" variant="" amount="ab" value="23,00" />
                    <Price service="Schneiden" variant="" amount="ab" value="20,50" />
                    <Price service="Kammsträhnen" variant="" amount="ab" value="18,50" />
                    <Price service="Bart Schneiden" variant="komplett" amount="ab" value="13,50" />
                    <Price service="Bart Schneiden" variant="Oberlippe/Kinn" amount="ab" value="6,50" />
                    <Price service="Bart Färben" variant="" amount="ab" value="15,50" />
                </div>
                <div className="kids mb-6">
                    <h3 className="text-center text-2xl mb-6 text-stone-600">Kinder</h3>
                    <Price service="Schneiden" variant="bis 3 Jahre" amount="" value="9,50" />
                    <Price service="Schneiden" variant="4–9 Jahre" amount="" value="13,50" />
                    <Price service="Schneiden" variant="10–14 Jahre" amount="" value="17,50" />
                    <Price service="Waschen" variant="" amount="" value="2,50" />
                    <Price service="Föhnen" variant="" amount="ab" value="3,50" />
                </div>
                <div className="waves mb-6">
                    <h3 className="text-center text-2xl mb-6 text-stone-600">Dauerwelle</h3>
                    <Price service="Normale Wicklung" variant="kurzes Haar" amount="ab" value="39,50" />
                    <Price service="Normale Wicklung" variant="langes Haar" amount="ab" value="49,50" />
                    <Price service="Spezial Wicklung" variant="kurzes Haar" amount="ab" value="49,50" />
                    <Price service="Spezial Wicklung" variant="langes Haar" amount="ab" value="59,50" />
                </div>
                <div className="care mb-6">
                    <h3 className="text-center text-2xl mb-6 text-stone-600">Haarpflege</h3>
                    <Price service="Haarwasser" variant="" amount="" value="2,90" />
                    <Price service="Haarwasser & Kopfmassage" variant="" amount="" value="5,90" />
                    <Price service="Haarkur / Maske" variant="" amount="" value="5,90" />
                    <Price service="2 Phasen Kur" variant="" amount="" value="6,90" />
                    <Price service="Haarspülung / Conditioner" variant="" amount="" value="1,90" />
                    <Price service="Saure Spülung / Kräuter Acid" variant="" amount="" value="2,90" />
                </div>
                <div className="coloration mb-6">
                    <h3 className="text-center text-2xl mb-6 text-stone-600">Farbe & Tönungen</h3>
                    <Price service="Farbe Ansatz" variant="bis 2 cm" amount="ab" value="31,50" />
                    <Price service="Farbe kurzes Haar" variant="komplett" amount="ab" value="37,50" />
                    <Price service="Farbe mittellanges Haar" variant="komplett" amount="ab" value="48,50" />
                    <Price service="Farbe langes Haar" variant="komplett" amount="ab" value="59,50" />
                    <Price service="Soft Tönung" variant="" amount="ab" value="25,50" />
                    <Price service="Farbauffrischung für Längen" variant="" amount="ab" value="25,50" />
                </div>
                <div className="strands mb-6">
                    <h3 className="text-center text-2xl mb-6 text-stone-600">Strähnen</h3>
                    <Price service="Kammsträhnen" variant="" amount="ab" value="18,50" />
                    <Price service="Foliensträhnen" variant="max. 10 Folien" amount="ab" value="26,00" />
                    <Price service="Foliensträhnen" variant="Pony über Wirbel" amount="ab" value="33,50" />
                    <Price service="Halber Kopf" variant="kurzes Haar" amount="ab" value="39,50" />
                    <Price service="Halber Kopf" variant="langes Haar" amount="ab" value="49,50" />
                    <Price service="Ganzer Kopf" variant="kurzes Haar" amount="ab" value="49,50" />
                    <Price service="Ganzer Kopf" variant="langes Haar" amount="ab" value="59,50" />
                </div>
                <div className="care mb-6">
                    <h3 className="text-center text-2xl mb-6 text-stone-600">Augen</h3>
                    <Price service="Augenbrauen rasieren" variant="" amount="" value="2,50" />
                    <Price service="Augenbrauen zupfen" variant="" amount="" value="5,50" />
                    <Price service="Augenbrauen färben" variant="" amount="" value="6,50" />
                    <Price service="Wimpern färben" variant="" amount="" value="9,50" />
                    <Price service="Komplettpaket" variant="Augenbrauen zupfen & färben, Wimpern färben" amount="" value="17,50" />
                </div>
                <p>Alle Preise sind inklusive Stylingprodukte und gesetzlicher MwSt.</p>
            </div>
        </div>
    );
}

export default Prices;