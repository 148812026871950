import Divider from "./Divider";

export default function Hero() {
    return (
        <div className="hero py-12 bg-stone-100">
            <div className="container mx-auto text-center">
                <h1 className="mb-16 text-5xl md:text-7xl text-stone-600">
                    Friseur Haargenau<br />
                    <span className="text-4xl md:text-5xl">in Bischberg</span>
                </h1>
                <Divider />
                <h4 className="mb-3 mt-16">Hairstyling im Trend</h4>
                <p className="max-w-lg mx-auto">Unser Friseur Laden in Bischberg bietet zahlreiche Leistungen für jeden. Auch Hausbesuche sind bei uns möglich. Sie brauchen den passenden Look für einen besonderen Anlass? Kommen sie bei uns vorbei und lassen sie sich beraten.</p>
                <p className="max-w-lg mx-auto">Irene Behr und ihr Team freut sich auf ihren Besuch.</p>
            </div>
        </div>
    );
}