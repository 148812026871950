import React from "react";
import Phone from "./Phone";
import Mail from "./Mail";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div id="footer">
      <div className="footer-top py-6 bg-stone-800">
        <div className="container mx-auto">
          <div className="grid sm:grid-cols-2 gap-4 text-white">
            <div>
              <h4 className="mb-2 text-lg">Friseur Haargenau</h4>
              <p className="mb-1">
                Hauptstr. 66
                <br />
                96120 Bischberg
              </p>
              <p>
                <Phone
                  fontSize="1rem"
                  classes="text-white hover:text-stone-300"
                />
                <Mail
                  mailAddress="info@haar-genau.net"
                  fontSize="1rem"
                  classes="text-white hover:text-stone-300"
                />
              </p>
            </div>
            <div>
            <h4 className="mb-2 text-lg">Öffnungszeiten</h4>
              <div className="opening flex justify-between">
                <p><strong>Montag</strong></p><p>9:00 – 17:00 Uhr</p>
              </div>
              <div className="opening flex justify-between">
                <p><strong>Dienstag</strong></p><p>9:00 – 17:00 Uhr</p>
              </div>
              <div className="opening flex justify-between">
                <p><strong>Mittwoch</strong></p><p>9:00 – 17:00 Uhr</p>
              </div>
              <div className="opening flex justify-between">
                <p><strong>Donnerstag</strong></p><p>9:00 – 17:00 Uhr</p>
              </div>
              <div className="opening flex justify-between">
                <p><strong>Freitag</strong></p><p>9:00 – 17:00 Uhr</p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <div className="footer-bottom py-3">
        <div className="container mx-auto text-right">
            <ul className="list-none">
                <li className="inline-block"><Link className="mr-3 p-2 text-stone-800 hover:text-stone-600 hover:underline" to="/impressum">Impressum</Link></li>
                <li className="inline-block"><Link className="text-stone-800 hover:text-stone-600 hover:underline" to="/datenschutz">Datenschutz</Link></li>
            </ul>
        </div>
      </div>
    </div>
  );
}
