import img from '../img/irene-behr.jpg';
import Divider from './Divider';
import Mail from './Mail';
import Phone from './Phone';

export default function Contact() {
    return (
        <div id="contact" className="contact-container py-12">
            <div className="container mx-auto text-center">
                <h2>Kontakt</h2>
                <Divider />
                <div className="contact flex flex-col items-center p-3">
                    <img width="140" height="140" className="rounded-full mb-3" src={img} />
                    <h4>Irene Behr</h4>
                    <p><small>Friseurin & Inhaberin</small></p>
                    <p className="mb-1">
                    Hauptstr. 66, 96120 Bischberg
                </p>
                    <Phone
                    fontSize="1rem"
                    classes="text-stone-600 hover:text-stone-300"
                    />
                    <Mail
                    mailAddress="info@haar-genau.net"
                    fontSize="1rem"
                    classes="text-stone-600 hover:text-stone-300"
                    />
                    <p className="text-center"><small>Termine nach Absprache</small></p>
                </div>
            </div>
        </div>
    );
}