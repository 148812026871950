import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import {Routes, Route} from 'react-router-dom';
import Home from './sites/Home';
import Legal from './sites/Legal';
import Privacy from './sites/Privacy';


function App() {

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/impressum' element={<Legal />} />
        <Route path='/datenschutz' element={<Privacy />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
