import React from "react";

export default function Price ({service, variant, amount, value}) {

    return (
        <div className="price flex items-baseline mb-2">
            <div className="service flex items-baseline mr-3">
                <p className="text-lg">{service}</p><span className="text-gray-500 ml-1">{variant && ' | '}{variant}</span>
            </div>
            <div className="points flex-1 border-b-2 border-dotted mr-3">

            </div>
            <div className="amount mr-3">
                {amount}
            </div>
            <div className="value">
                {value}&nbsp;€
            </div>
        </div>
    );

}