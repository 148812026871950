export default function Legal() {
  return (
    <div className="container mx-auto py-7">
      <h1>Impressum</h1>

      <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
      <p>
        Irene Behr
        <br />
        Hauptstr. 66
        <br />
        96120 Bischberg
      </p>

      <h2>Kontakt</h2>
      <p>
        Telefon: 0176 84338500
        <br />
        E-Mail: info@haar-genau.net
      </p>

      <h2>Berufsbezeichnung und berufsrechtliche Regelungen</h2>
      <p>
        Berufsbezeichnung:
        <br />
        Friseurin
      </p>
      <p>
        Zust&auml;ndige Kammer:
        <br />
        HWK Bamberg
      </p>
      <p>
        Verliehen in:
        <br />
        Deutschland
      </p>
      <p>Es gelten folgende berufsrechtliche Regelungen:</p>
      <h2>Redaktionell verantwortlich</h2>
      <p>Irene Behr</p>

      <h2>EU-Streitschlichtung</h2>
      <p>
        Die Europ&auml;ische Kommission stellt eine Plattform zur
        Online-Streitbeilegung (OS) bereit:{" "}
        <a
          href="https://ec.europa.eu/consumers/odr/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://ec.europa.eu/consumers/odr/
        </a>
        .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
      </p>

      <h2>
        Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
      </h2>
      <p>
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
        vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>
    </div>
  );
}
