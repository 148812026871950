import Contact from "../components/Contact";
import Hero from "../components/Hero";
import Prices from "../components/Prices";
import Services from "../components/Services";

export default function Home() {

    return (
        <>
            <Hero />
            <Contact />
            <Services />
            <Prices />
        </>
    );

}